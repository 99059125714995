



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class TimePicker extends Vue {
  showMenu = false;

  @Prop({ type: String })
  value!: string;

  @Emit('input')
  input(value: string) {
    this.showMenu = false;
    return value;
  }
}
