

























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class DatePicker extends Vue {
  showMenu = false;

  @Prop({ type: String, default: 'LL' })
  format!: string;

  @Prop({ type: String })
  value!: string;

  get valuePrefix() {
    const value = this.value || new Date().toISOString();
    return value.substr(0, 10);
  }

  @Emit('input')
  input(value: string) {
    this.showMenu = false;
    return `${value}T12:00:00.000Z`;
  }
}
