













import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component
export default class LanguageSelect extends Vue {
  get languages() {
    return [
      { id: 'en', label: this.$t('language.en') },
      { id: 'es', label: this.$t('language.es') },
    ];
  }

  @Prop({ type: String })
  value!: string;

  @Emit('input')
  input(value: string) {
    return value;
  }

  mounted() {
    if (!this.value) {
      this.input('es');
    }
  }
}
