
























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Money, { Currency } from '../dto/money.dto';

@Component
export default class MoneyInput extends Vue {
  currencies = Object.values(Currency);

  @Prop({ type: Object })
  value!: Money;

  onValueChange(value: number) {
    this.input({ value: Number(value), currency: this.value.currency });
  }

  onCurrencyChange(currency: Currency) {
    this.input({ value: this.value.value, currency });
  }

  @Emit('input')
  input(value: Money) {
    return value;
  }
}
