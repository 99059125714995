import api from '@/app/core/services/api.service';
import { ChannelsDto } from '../dto/channels.dto';

const getChannels = () => (
  api.get<ChannelsDto>('/host/channels')
);

export default {
  getChannels,
};
