























import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import hostService from '@/app/host/services/host.service';
import { ReservationChannelDto } from '@/app/reservations/dto/reservation.dto';
import ReservationChannel from '@/app/reservations/components/reservation-channel.vue';
import HandlesErrorMixin from '../mixins/handles-error.mixin';

@Component({
  components: {
    ReservationChannel,
  },
})
export default class ChannelSelect extends Mixins(HandlesErrorMixin) {
  loading = false;

  channels: ReservationChannelDto[] = [];

  @Prop({ type: Object })
  value!: ReservationChannelDto;

  @Emit('input')
  input(value: ReservationChannelDto) {
    return value;
  }

  async getChannels() {
    this.loading = true;

    try {
      const res = await hostService.getChannels();
      this.channels = res.data.items;

      if (!this.value && this.channels.length > 0) {
        this.input(this.channels[0]);
      }
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.getChannels();
  }
}
